import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { onMessage, isSupported } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";

const Page1 = lazy(() => import("./pages/Page1"));
const Page2 = lazy(() => import("./pages/Page2"));
const Page3 = lazy(() => import("./pages/Page3"));
const Home = lazy(() => import("./pages/Home"));

const App = () => {
  const deferredPrompt = useRef(null);
  const [isBtnVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {

    handleForegroundNotificaiton();

    function handleForegroundNotificaiton() {
      onMessage(messaging, (payload) => {
        const ns = payload.notification;
        try {
          new Notification(ns.title, { body: ns.body });
        } catch(e) {
          console.log("error in showing notificaiton")
        }
      });
    } 

    function handleBeforeInstallPromp(event) {
      console.log('beforeinstallprompt fired');
      event.preventDefault();
      deferredPrompt.current = event;
      setIsButtonVisible(true);
      return false;
    }

    function handleAppInstalled() {
      // Hide the app-provided install promotion
      setIsButtonVisible(false);
      // Clear the deferredPrompt so it can be garbage collected
      deferredPrompt.current = null;
      // Optionally, send analytics event to indicate successful install
      console.log('PWA was installed');
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPromp);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPromp);
      window.removeEventListener('appinstalled', handleAppInstalled);
    }
  }, [])

  function onInstallButtonClick() {
    if (deferredPrompt.current) {
      deferredPrompt.current.prompt();

      deferredPrompt.current.userChoice.then(function (choiceResult) {
        console.log(choiceResult.outcome);

        if (choiceResult.outcome === 'dismissed') {
          console.log('User cancelled installation');
        } else {
          console.log('User added to home screen');
          setIsButtonVisible(false);
          deferredPrompt.current = null;
        }
      });
    }
  }

  return (
    <div style={{
      position: 'relative',
      minHeight: '100vh'
    }}>
      {
        isBtnVisible && (
          <button style={{
            position: "absolute",
            bottom: '16px',
            margin: '0 16px'
          }}
          onClick={onInstallButtonClick}>
            Install PWA
          </button>
        )
      }
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/page1" element={<Page1 />} />
            <Route path="/page2" element={<Page2 />} />
            <Route path="/page3" element={<Page3 />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  )
};

export default App;