// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCMJjAvryG1jrpvmyVzMTBEfO7FAZx2rlk",
  authDomain: "pwapushnstest.firebaseapp.com",
  projectId: "pwapushnstest",
  storageBucket: "pwapushnstest.appspot.com",
  messagingSenderId: "577297251523",
  appId: "1:577297251523:web:fb8a655186b46eaf3d3676",
  measurementId: "G-ZFBVB8G6TX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const VAPID = 'BM84Pt7TSul3hpGa-Tsw_JLYjex3FBhYjpRGpTf3yl_LBNkAzWS0HLgTTawGNrKrVYLjDNdGwmvKTQo2C_nv7pc';
